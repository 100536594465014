import { captureException } from "@sentry/react";
import {
  Diff,
  diff as deepDiff,
  applyChange,
  revertChange,
  PreFilter,
} from "deep-diff";
import { Doc, Map, UndoManager } from "yjs";
import { CanvasWidgetsReduxState } from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { MetaState } from "legacy/reducers/entityReducers/metaReducer";
import { fastClone } from "utils/clone";
import { getPathsFromDiff } from "./BaseReplayUtils";
import type * as BackendTypes from "store/slices/apisV2/backend-types";

type WidgetsDSL = { widgets: CanvasWidgetsReduxState; widgetMeta: MetaState };
export type ReplayManagerDSLType = WidgetsDSL | BackendTypes.Api;
type ReplayManagerDSLDiff =
  | Diff<WidgetsDSL, WidgetsDSL>
  | Diff<BackendTypes.Api, BackendTypes.Api>;
type FilterDiffsFunction = (
  diffs: Diff<ReplayManagerDSLType, ReplayManagerDSLType>[],
) => Diff<ReplayManagerDSLType, ReplayManagerDSLType>[];
export type ProcessDiffFuncType = (...args: unknown[]) => void;

const _DIFF_ = "diff";
type ReplayType = "UNDO" | "REDO";

export default class BaseDSLReplayManager {
  diffMap: any;
  undoManager: UndoManager;
  dsl: ReplayManagerDSLType;
  processDiff: ProcessDiffFuncType;
  itemLastChangeKey: undefined | string;
  logs: any[] = [];
  filterDiffPaths?: PreFilter<ReplayManagerDSLType, ReplayManagerDSLType>;
  filterDiffs?: FilterDiffsFunction;

  constructor(
    initialDsl: ReplayManagerDSLType,
    processDiff: ProcessDiffFuncType,
    options?: {
      filterDiffPaths?: PreFilter<ReplayManagerDSLType, ReplayManagerDSLType>;
      filterDiffs?: FilterDiffsFunction;
    },
  ) {
    const doc = new Doc();
    this.diffMap = doc.get("map", Map);
    this.dsl = initialDsl;
    this.processDiff = processDiff;
    this.diffMap.set(_DIFF_, []);
    this.undoManager = new UndoManager(this.diffMap, { captureTimeout: 100 });
    this.filterDiffPaths = options?.filterDiffPaths;
    this.filterDiffs = options?.filterDiffs;
  }

  clear(dsl: ReplayManagerDSLType) {
    this.undoManager.clear();
    this.dsl = dsl;
  }

  getUndoStackLength() {
    return this.undoManager.undoStack.length;
  }

  canReplay(replayType: ReplayType) {
    switch (replayType) {
      case "UNDO":
        return this.undoManager.undoStack.length > 0;
      case "REDO":
        return this.undoManager.redoStack.length > 0;
      default:
        return false;
    }
  }

  getDiffs() {
    return this.diffMap.get(_DIFF_);
  }

  replay(replayType: ReplayType) {
    const start = performance.now();

    if (this.canReplay(replayType)) {
      let diffs;

      switch (replayType) {
        case "UNDO":
          diffs = this.getDiffs();
          this.undoManager.undo();
          break;
        case "REDO":
          this.undoManager.redo();
          diffs = this.getDiffs();
          break;
      }

      const replay = this.applyDiffs(diffs, replayType);
      const stop = performance.now();

      this.logs.push({
        log: `replay ${replayType}`,
        undoTime: `${stop - start} ms`,
        replay: replay,
        diffs: diffs,
      });
      return {
        replayDSL: this.dsl,
        replay,
        logs: this.logs,
        event: `REPLAY_${replayType}`,
        timeTaken: stop - start,
        paths: getPathsFromDiff(diffs),
      };
    }

    return null;
  }

  update(updatedDSL: ReplayManagerDSLType) {
    updatedDSL = fastClone(updatedDSL);
    const startTime = performance.now();
    const diffs = deepDiff(this.dsl, updatedDSL, this.filterDiffPaths);
    let filteredDiffs = diffs;
    if (this.filterDiffs && diffs) {
      filteredDiffs = this.filterDiffs(diffs);
    }

    if (diffs && diffs.length) {
      this.dsl = updatedDSL;
      this.diffMap.set(_DIFF_, filteredDiffs);
    }
    const endTime = performance.now();
    this.logs.push({
      log: "replay updating",
      updateTime: `${endTime - startTime} ms`,
    });
  }

  clearLogs() {
    this.logs = [];
  }

  applyDiffs(
    diffs: Array<ReplayManagerDSLDiff>,
    replayType: ReplayType,
  ): Record<string, any> {
    const replay = {};
    const isUndo = replayType === "UNDO";
    const applyDiff = isUndo ? revertChange : applyChange;
    for (const diff of diffs) {
      if (!Array.isArray(diff.path) || diff.path.length === 0) {
        continue;
      }
      try {
        this.processDiff(this.dsl, diff, replay, isUndo);
        applyDiff(this.dsl, true, diff);
      } catch (e) {
        captureException(e, {
          extra: {
            diff,
            updateLength: diffs.length,
          },
        });
      }
    }
    return replay;
  }
}
