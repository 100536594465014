import {
  isApplicationUrl,
  isScheduledJobUrl,
  isWorkflowUrl,
} from "@superblocksteam/shared";
import styled from "styled-components";
import ErrorComponent from "components/app/Error/ErrorComponent";
import { useFeatureFlag } from "hooks/ui";
import { API_STATUS_CODES } from "legacy/constants/ApiConstants";
import { Flag } from "store/slices/featureFlags";
import { EntityType } from "utils/entity";
import RequestAccess from "./RequestAccess";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - ${(props) => props.theme.legacy.headerHeight});
  background-color: #fafafa;
  text-align: center;
  padding-top: calc(${(props) => props.theme.legacy.headerHeight});
  .bold-text {
    font-weight: ${(props) => props.theme.legacy.fontWeights[3]};
    font-size: 24px;
  }
  .page-unavailable-img {
    width: 35%;
  }
  .button-position {
    margin: auto;
  }
`;

const ResourceForbidden = () => {
  const url = window.location.pathname;
  let entityType = undefined;
  const rbacV2Enabled = useFeatureFlag(Flag.ENABLE_RBAC_V2);
  let resourceTypeName = "resource";
  if (isApplicationUrl(url)) {
    entityType = EntityType.APPLICATION;
    resourceTypeName = "Application";
  } else if (isWorkflowUrl(url)) {
    entityType = EntityType.WORKFLOW;
    resourceTypeName = "Workflow";
  } else if (isScheduledJobUrl(url)) {
    entityType = EntityType.SCHEDULED_JOB;
    resourceTypeName = "Scheduled Job";
  }
  return (
    <Wrapper>
      {entityType && !rbacV2Enabled ? (
        <RequestAccess entityType={entityType} />
      ) : (
        <ErrorComponent
          errorCode={API_STATUS_CODES.RESOURCE_FORBIDDEN}
          title={"You do not have access"}
          errorMessage={`Sorry you don’t have access to this ${resourceTypeName}. Reach out to your team for access or contact support if you believe this is an error.`}
          buttonText="Find admins"
          handleButtonClick={() => {
            window.location.href = "/users";
          }}
          secondaryButtonText="Return home"
          secondaryButtonAction={() => {
            window.location.href = "/";
          }}
        />
      )}
    </Wrapper>
  );
};

export default ResourceForbidden;
