import styled from "styled-components";
import ErrorComponent from "components/app/Error/ErrorComponent";
import { API_STATUS_CODES } from "legacy/constants/ApiConstants";
import { STATUS_PAGE } from "pages/routes";

const Wrapper = styled.div`
  height: calc(100vh - ${(props) => props.theme.legacy.headerHeight});
  background-color: ${(props) => props.theme.legacy.colors.homepageBackground});
  text-align: center;
  padding-top: calc(${(props) => props.theme.legacy.headerHeight} + 50px);
  .bold-text {
    font-weight: ${(props) => props.theme.legacy.fontWeights[3]};
    font-size: 24px;
  }
  .page-unavailable-img {
    width: 35%;
  }
  .button-position {
    margin: auto;
  }
`;

const ServerUnavailable = () => {
  return (
    <Wrapper>
      <ErrorComponent
        errorCode={API_STATUS_CODES.SERVER_ERROR}
        title="Something went wrong. Our team is on it!"
        errorMessage={
          <>
            <span>
              {"We are having trouble contacting the Superblocks server, see "}
            </span>
            <a href={STATUS_PAGE} target="_blank" rel="noreferrer">
              Status Page
            </a>
          </>
        }
      />
    </Wrapper>
  );
};

export default ServerUnavailable;
