import styled from "styled-components";
import ErrorComponent from "components/app/Error/ErrorComponent";
import { LOGOUT_URL, SUPPORT_EMAIL_ADDRESS } from "legacy/constants/routes";

const Wrapper = styled.div`
  height: calc(100vh - ${(props) => props.theme.legacy.headerHeight});
  background-color: ${(props) => props.theme.legacy.colors.homepageBackground};
  text-align: center;
  padding-top: calc(${(props) => props.theme.legacy.headerHeight} + 50px);
  .bold-text {
    font-weight: ${(props) => props.theme.legacy.fontWeights[3]};
    font-size: 24px;
  }
  .page-unavailable-img {
    width: 35%;
  }
  .button-position {
    margin: auto;
  }
`;

const RequestUnathorized = () => {
  return (
    <Wrapper>
      <ErrorComponent
        title="You do not have permission to log in to Superblocks."
        errorMessage={`Please request access from a Superblocks admin, or contact the Superblocks team at ${SUPPORT_EMAIL_ADDRESS}.`}
        buttonText={"Retry Login"}
        buttonPath={LOGOUT_URL}
      />
    </Wrapper>
  );
};

export default RequestUnathorized;
