import styled from "styled-components";
import { FullPageSpinner } from "components/ui/FullPageSpinner";

const FixedOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
`;

const PageLoadingBar = () => (
  <FixedOverlay>
    <FullPageSpinner />
  </FixedOverlay>
);

export default PageLoadingBar;
