import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { appendParams, extractParamsWithPrefix } from "@superblocksteam/shared";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router";
import envs from "env";
import { HOME_URL } from "legacy/constants/routes";
import loginState from "utils/loginState";

// Enables logging for all steps of the lock acquisition process
// so that you can see what's happening
const DEBUG = false;
const debugLog = (...args: any[]) => {
  if (DEBUG) {
    console.log(...args);
  }
};

const AUTH_LOCK_NAME = "auth0_authentication";
const RETRY_DELAY = 300;
const MAX_CONCURRENT = 3;

const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms));

type ResolveFn = () => void;

async function acquireMultiLock({
  key = AUTH_LOCK_NAME,
  length = MAX_CONCURRENT,
  retryDelay = RETRY_DELAY,
} = {}): Promise<ResolveFn> {
  const lockNames = Array.from({ length }, (_, i) => `${key}_${i}`);

  while (true) {
    for (const name of lockNames) {
      let resolve: ResolveFn | undefined = undefined;
      const promise = new Promise((res) => {
        resolve = res as ResolveFn;
      });

      const lockHolder: { lock: Lock | null | false } = { lock: false };

      // request the lock
      navigator.locks.request(name, { ifAvailable: true }, (lock) => {
        lockHolder.lock = lock;
        return promise;
      });

      // wait for the lock to be acquired
      while (lockHolder.lock === false) {
        await sleep(1);
      }

      if (lockHolder.lock) {
        return resolve as unknown as ResolveFn;
      }
    }
    await sleep(retryDelay);
  }
}

function acquireAuthenticationLockWithRetry(
  onAcquired: (release: () => void) => void,
  onError: (error: Error) => void,
): () => void {
  let cleanup: (() => void) | null = null;
  let isActive = true;

  const attempt = async () => {
    if (!isActive) return;

    try {
      const release = await acquireMultiLock();
      if (!isActive) {
        release();
        return;
      }
      cleanup = release;
      onAcquired(release);
    } catch (error) {
      if (!isActive) return;
      console.error("Failed to acquire authentication lock:", error);
      onError(error as Error);
    }
  };

  attempt();

  return () => {
    isActive = false;
    if (cleanup) {
      cleanup();
      cleanup = null;
    }
  };
}

// This component handles the initial auth loading state
function AuthLoadingHandler({ onComplete }: { onComplete: () => void }) {
  const { isLoading } = useAuth0();
  const didComplete = useRef(false);

  useEffect(() => {
    if (!isLoading && !didComplete.current) {
      debugLog("auth loading complete");
      didComplete.current = true;
      onComplete();
    }
  }, [isLoading, onComplete]);

  return null;
}

const Auth0ProviderWithHistory = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const [authTransactionComplete, setAuthTransactionComplete] = useState(false);
  const [authLock, setAuthLock] = useState<(() => void) | null>(null);
  const lockRef = useRef<(() => void) | null>(null);

  // This gets called only during the redirect callback
  const onRedirectCallback = (appState: any) => {
    if (lockRef.current) {
      debugLog("releasing lock in redirect callback");
      lockRef.current();
      lockRef.current = null;
    }
    setAuthLock(null);
    setAuthTransactionComplete(true);
    loginState.newlyLoggedIn = true;
    let url =
      appState?.returnUrl ??
      (appState?.referrer
        ? `${HOME_URL}?referrer=${appState.referrer}`
        : HOME_URL);
    const utmParams = extractParamsWithPrefix(appState, "utm_");
    const sbTrackingParams = extractParamsWithPrefix(appState, "sbt_");
    url = appendParams(url, utmParams);
    url = appendParams(url, sbTrackingParams);
    if (url.startsWith("/code-mode")) {
      window.location.href = url;
    } else {
      navigate(url);
    }
  };

  // This handles the initial auth check completion
  const onAuthCheckComplete = useCallback(() => {
    if (lockRef.current) {
      debugLog("releasing lock in auth check complete");
      lockRef.current();
      lockRef.current = null;
    }
    setAuthLock(null);
    setAuthTransactionComplete(true);
  }, []);

  useEffect(() => {
    let mounted = true;
    let cancelRetry: (() => void) | null = null;

    if (!authTransactionComplete && !lockRef.current) {
      debugLog("attempting to acquire lock");

      cancelRetry = acquireAuthenticationLockWithRetry(
        (releaseFn) => {
          if (!mounted) return;
          debugLog("acquired auth lock", releaseFn);
          lockRef.current = releaseFn;
          setAuthLock(() => releaseFn);
        },
        (error) => {
          if (!mounted) return;
          console.error("failed to acquire lock:", error);
        },
      );
    }

    return () => {
      mounted = false;
      if (cancelRetry) {
        cancelRetry();
      }
      if (lockRef.current) {
        debugLog("releasing lock in cleanup");
        lockRef.current();
        lockRef.current = null;
      }
    };
  }, [authTransactionComplete]);

  const shouldRender = authLock !== null || authTransactionComplete;
  debugLog(
    "render check - authLock:",
    authLock,
    "authTransactionComplete:",
    authTransactionComplete,
    "shouldRender:",
    shouldRender,
  );

  if (!shouldRender) {
    return null;
  }

  debugLog("about to render Auth0Provider");

  return (
    <Auth0Provider
      domain={envs.get("SUPERBLOCKS_UI_AUTH_ISSUER_URL")}
      clientId={envs.get("SUPERBLOCKS_UI_AUTH_CLIENT_ID")}
      redirectUri={window.location.origin}
      audience={envs.get("SUPERBLOCKS_UI_AUTH_AUDIENCE")}
      scope="openid profile email"
      // TODO consider using session storage for XSS protection
      cacheLocation={(window as any).Cypress ? "localstorage" : "memory"}
      onRedirectCallback={onRedirectCallback}
    >
      <>
        <AuthLoadingHandler onComplete={onAuthCheckComplete} />
        {children}
      </>
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
