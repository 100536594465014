import React from "react";
import { ReactComponent as PlusIcon } from "assets/icons/common/plus.svg";
import IconButton, { IconButtonProps } from "./IconButton";

const AddIconButton = React.forwardRef<
  HTMLButtonElement,
  Omit<IconButtonProps, "icon">
>((props, ref) => {
  return <IconButton icon={<PlusIcon />} {...props} ref={ref} />;
});

AddIconButton.displayName = "AddIconButton";

export default AddIconButton;
