import React from "react";
import { useNavigate } from "react-router-dom";
import { useFeatureFlag } from "hooks/ui/useFeatureFlag";
import {
  CREATE_MODAL_URL,
  HomeModalRoutes,
  HOME_MODAL_URL,
} from "legacy/constants/routes";
import { Flag } from "store/slices/featureFlags/models/Flags";
import { EntityType } from "utils/entity";

/**
 * A link that navigates to the entity creation modal.
 * It uses the feature flag to determine whether to use the new or old modal.
 */
const CreateEntityLink = ({
  children,
  modalRoute,
}: {
  children: React.ReactNode;
  modalRoute: HomeModalRoutes;
}) => {
  const navigate = useNavigate();
  const isNewHomepageEnabled = useFeatureFlag(Flag.ENABLE_NEW_HOME_PAGE);
  const entityCreationUrl = isNewHomepageEnabled
    ? HOME_MODAL_URL(modalRoute)
    : CREATE_MODAL_URL(EntityType.APPLICATION);

  return (
    <div
      onClick={() => {
        navigate({ pathname: entityCreationUrl });
      }}
    >
      {children}
    </div>
  );
};

export const CreateApplicationLink = (props: { children: React.ReactNode }) => {
  const modalRoute = HomeModalRoutes.CREATE_APPLICATION;
  return <CreateEntityLink modalRoute={modalRoute} {...props} />;
};

export const CreateWorkflowLink = (props: { children: React.ReactNode }) => {
  const modalRoute = HomeModalRoutes.CREATE_WORKFLOW;
  return <CreateEntityLink modalRoute={modalRoute} {...props} />;
};

export const CreateScheduledJobLink = (props: {
  children: React.ReactNode;
}) => {
  const modalRoute = HomeModalRoutes.CREATE_SCHEDULED_JOB;
  return <CreateEntityLink modalRoute={modalRoute} {...props} />;
};
