import { DefaultTheme } from "styled-components";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

export enum IconSize {
  XXS = "extraExtraSmall",
  XS = "extraSmall",
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  XL = "extraLarge",
  XXL = "extraExtraLarge",
  XXXL = "extraExtraExtraLarge",
}

export const sizeHandler = (
  size: IconSize | undefined,
  theme: DefaultTheme,
) => {
  let iconSize = 0;
  switch (size) {
    case IconSize.XXS:
      iconSize = theme.legacy.iconSizes.XXS;
      break;
    case IconSize.XS:
      iconSize = theme.legacy.iconSizes.XS;
      break;
    case IconSize.SMALL:
      iconSize = theme.legacy.iconSizes.SMALL;
      break;
    case IconSize.MEDIUM:
      iconSize = theme.legacy.iconSizes.MEDIUM;
      break;
    case IconSize.LARGE:
      iconSize = theme.legacy.iconSizes.LARGE;
      break;
    case IconSize.XL:
      iconSize = theme.legacy.iconSizes.XL;
      break;
    case IconSize.XXL:
      iconSize = theme.legacy.iconSizes.XXL;
      break;
    case IconSize.XXXL:
      iconSize = theme.legacy.iconSizes.XXXL;
      break;
    default:
      iconSize = theme.legacy.iconSizes.SMALL;
      break;
  }
  return iconSize;
};

const labelIconPrefix = styleAsClass`
  width: 1.5px;
  height: 12px;
  background: transparent;
  border-radius: 32px;
  position: absolute;
  left: -6px;
`;

export const PaddingIconPrefix = (
  <div
    className={labelIconPrefix}
    style={{ background: colors.ACCENT_BLUE_500 }}
  />
);

export const MarginIconPrefix = (
  <div
    className={labelIconPrefix}
    style={{ background: colors.ACCENT_ORANGE }}
  />
);

export const SpacingIconPrefix = (
  <div className={labelIconPrefix} style={{ background: colors.ACCENT_PINK }} />
);
