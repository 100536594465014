import { AccessMode } from "@superblocksteam/shared";
import { useSelector } from "react-redux";
import styled from "styled-components";
import TokenProvider from "auth/token";
import ErrorComponent from "components/app/Error/ErrorComponent";
import { API_STATUS_CODES, ERROR_CODES } from "legacy/constants/ApiConstants";
import { getAccessMode } from "legacy/selectors/usersSelectors";

const Wrapper = styled.div`
  padding: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  div {
    padding-top: 0px;
    max-width: 520px;
  }
`;
export const EmbeddedErrorPage = ({ code }: { code: string | number }) => {
  let pageContent = null;
  const accessMode = useSelector(getAccessMode);
  const hasTokenBeenSet = TokenProvider.getTokenType() === "embed";
  const isEmbedUser =
    accessMode === AccessMode.EXTERNAL_USER || hasTokenBeenSet;
  switch (code) {
    case "NOT_DEPLOYED":
      pageContent = (
        <Wrapper>
          <ErrorComponent
            errorCode={API_STATUS_CODES.REQUEST_UNAUTHORIZED}
            title="Application not deployed"
            errorMessage="This application has not been deployed yet. Please deploy the application before embedding it."
          />
        </Wrapper>
      );
      break;
    case API_STATUS_CODES.RESOURCE_FORBIDDEN:
      pageContent = (
        <Wrapper>
          <ErrorComponent
            errorCode={API_STATUS_CODES.RESOURCE_FORBIDDEN}
            title="Access denied"
            errorMessage="You don’t have permission to access this page. Please contact your admin if you believe this is in error."
          />
        </Wrapper>
      );
      break;
    case API_STATUS_CODES.RESOURCE_NOT_FOUND:
      if (accessMode === AccessMode.VISITOR && !isEmbedUser) {
        // show a unauthorized page for visitors, with a prompt to try logging in
        pageContent = (
          <Wrapper>
            <ErrorComponent
              errorCode={API_STATUS_CODES.RESOURCE_FORBIDDEN}
              title="Authentication failed"
              errorMessage="Either this application doesn’t exist or you do not have access to it."
              showActionsOnEmbedRoute={true}
              buttonText="Log in"
              handleButtonClick={() => {
                // open the login page in another tab
                window.open("/", "_blank");
              }}
            />
          </Wrapper>
        );
      } else {
        pageContent = (
          <Wrapper>
            <ErrorComponent
              errorCode={API_STATUS_CODES.RESOURCE_NOT_FOUND}
              title="Application not found"
              errorMessage="Either this application doesn’t exist or you do not have access to it."
            />
          </Wrapper>
        );
      }

      break;
    case ERROR_CODES.JWT_EXPIRED: {
      pageContent = (
        <Wrapper>
          <ErrorComponent
            errorCode={API_STATUS_CODES.REQUEST_UNAUTHORIZED}
            title="Session expired"
            errorMessage={
              "Your access token has expired, please sign in again to continue. If you continue to experience issues, please contact your admin for assistance."
            }
          />
        </Wrapper>
      );
      break;
    }
    case API_STATUS_CODES.REQUEST_UNAUTHORIZED:
    case ERROR_CODES.REQUEST_UNAUTHORIZED:
      if (accessMode !== AccessMode.VISITOR || isEmbedUser) {
        // If the user is an external user, show a different message
        pageContent = (
          <Wrapper>
            <ErrorComponent
              errorCode={API_STATUS_CODES.RESOURCE_FORBIDDEN}
              title="Authentication failed"
              errorMessage="We couldn’t verify your identity because the access token provided is invalid. Please contact your admin for assistance."
            />
          </Wrapper>
        );
      } else {
        pageContent = (
          <Wrapper>
            <ErrorComponent
              errorCode={API_STATUS_CODES.RESOURCE_FORBIDDEN}
              title="Authentication failed"
              errorMessage={
                "We couldn't verify your identity because no access token was provided. Please try logging in again."
              }
              showActionsOnEmbedRoute={true}
              buttonText="Log in"
              handleButtonClick={() => {
                // open the login page in another tab
                window.open("/", "_blank");
              }}
            />
          </Wrapper>
        );
      }
      break;
    case API_STATUS_CODES.SERVER_ERROR:
    default:
      pageContent = (
        <Wrapper>
          <ErrorComponent
            errorCode={API_STATUS_CODES.SERVER_ERROR}
            title="Something went wrong"
            errorMessage="Whoops! There was an error loading the page. Please refresh to try again or contact your administrator for assistance."
          />
        </Wrapper>
      );

      break;
  }
  return <>{pageContent}</>;
};
