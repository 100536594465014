import {
  Agent,
  ApiResponseType,
  ApiTriggerType,
  AuthConfig,
  AuthType,
  IntegrationAuthType,
  Organization,
  Profile,
  RestApiIntegrationDatasourceConfiguration,
  ViewMode,
  WorkflowExecutionParamsKey,
  getDisplayName,
  SupersetIntegrationDto,
  AccessMode,
} from "@superblocksteam/shared";
import { get } from "lodash";
import { call, put, select, take } from "redux-saga/effects";
import AuthProvider from "auth/auth0";
import { ApiInfo } from "legacy/constants/ApiConstants";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { Profiles } from "legacy/reducers/entityReducers/appReducer";
import {
  getAppProfilesInCurrentMode,
  getAppViewMode,
  getProfileForTest,
} from "legacy/selectors/applicationSelectors";
import {
  getDeveloperPreferences,
  getV2ApiAppInfoById,
} from "legacy/selectors/sagaSelectors";
import { getAccessMode } from "legacy/selectors/usersSelectors";
import { ExecutionParamDto } from "store/slices/apis/types";
import {
  checkAuthV3,
  checkIdpToken,
} from "store/slices/apisShared/client-auth";
import * as BackendTypes from "store/slices/apisV2/backend-types";
import { selectAllPluginDatasources } from "store/slices/datasources";
import { getConfigFromIntegrationWithProfileId } from "store/slices/datasources/utils";
import { Flag, selectFlagById } from "store/slices/featureFlags";
import { selectOrganizations } from "store/slices/organizations";
import { nonSagaFunctions } from "store/utils/saga";

import logger from "utils/logger";
import { sendWarningUINotification } from "utils/notification";
import { selectCachedControlFlowById } from "../control-flow/control-flow-selectors";
import { BlockType, StepBlock } from "../control-flow/types";
import { selectApiExecutionResult } from "../selectors";
import { ApiDtoWithPb } from "../slice";
import {
  EnrichedExecutionProcessingOptions,
  ExecutionResponse,
} from "../types";
import StreamDispatcher from "../utils/StreamDispatcher";
import { getTriggerTypeFromApi } from "../utils/api-utils";
import {
  decodeBytestringsInV2ExecutionResponse,
  parseStreamResult,
} from "../utils/execution-response";
import { getV2ApiId } from "../utils/getApiIdAndName";
import { computeEnrichedExecutionSaga } from "./computeEnrichedExecution";

export function* getMessageHandlers(
  apiId: string,
  includeOutputs: boolean,
  executionProcessingOptions?: EnrichedExecutionProcessingOptions,
  isSingleBlock?: boolean,
  manualRun?: boolean,
) {
  const apiInfo: ApiInfo = yield select((state) =>
    getV2ApiAppInfoById(state, apiId),
  );

  const developerPreferences: ReturnType<typeof getDeveloperPreferences> =
    yield select(getDeveloperPreferences);

  const responseType = apiInfo.responseType ?? ApiResponseType.SYNC;
  const dispatchQueue = StreamDispatcher(apiId, apiInfo);
  const onMessage = (message: any) => {
    if (responseType !== ApiResponseType.STREAM && includeOutputs) {
      sendWarningUINotification({
        message:
          "Received a stream message but API response type is set to sync",
        description:
          "This message will be ignored. Please update your API response type to stream or remove any Stream and Send blocks",
      });
      return;
    }

    const shouldTrigger = !(
      manualRun &&
      !developerPreferences.application.triggerFrontendEventHandlersOnManualRun
    );

    if (shouldTrigger) {
      dispatchQueue.addMessage(message);
    }
  };

  const events: Array<BackendTypes.StreamEvent> = [];
  const processStreamEvents = (event: BackendTypes.StreamEvent) => {
    events.push(event);
    nonSagaFunctions.forkSaga(
      computeEnrichedExecutionSaga.apply({
        executionResult: parseStreamResult(events, {
          includeFinalOutput: responseType !== ApiResponseType.STREAM,
        }),
        apiId,
        options: executionProcessingOptions,
      }),
    );
  };

  // We ALWAYS use stream in edit mode to get the events in the UI
  // We use sync in run mode as an optimization to avoid the overhead of streaming if we don't need it
  const executeResponseType = includeOutputs
    ? ApiResponseType.STREAM
    : responseType;

  const previousResult: ReturnType<typeof selectApiExecutionResult> =
    yield select(selectApiExecutionResult, apiId);

  const parseFinalResult = (
    result: BackendTypes.ApiV2ExecutionResponse,
  ):
    | BackendTypes.ApiV2ExecutionResponse
    | {
        systemError: string;
      }
    | undefined => {
    let parsedResult: BackendTypes.ApiV2ExecutionResponse | undefined;
    let systemError: string | undefined;
    // Don't parse a final result if we're in single block mode

    if (result && "systemError" in result && !isSingleBlock) {
      systemError = result.systemError as string;
    } else {
      // result could still be undefined even for a successful result - the events will be populated
      switch (executeResponseType) {
        case ApiResponseType.SYNC: {
          result && decodeBytestringsInV2ExecutionResponse(result);
          parsedResult = result;
          break;
        }
        case ApiResponseType.STREAM: {
          parsedResult = parseStreamResult(events, {
            includeFinalOutput: responseType !== ApiResponseType.STREAM,
          });
          parsedResult && decodeBytestringsInV2ExecutionResponse(parsedResult);
          break;
        }
      }
    }
    if (isSingleBlock) {
      parsedResult = {
        ...parsedResult,
        output: (previousResult as any)?.output,
      } as BackendTypes.ApiV2ExecutionResponse;
    }

    const endResult = systemError ? { systemError } : parsedResult;
    return endResult;
  };

  return {
    onMessage,
    processStreamEvents,
    responseType,
    parseFinalResult,
  };
}

function* requestDatasourceAuth(datasourceId: string) {
  yield put({
    type: ReduxActionTypes.REQUEST_DATASOURCE_AUTH,
    payload: { datasourceId },
  });
}

export function* authenticateFeDatasources(
  api: ApiDtoWithPb,
  profileId: string,
  profile: undefined | Profile,
  // FIXME: These are unused - can they be removed?
  targetEnvironment: string,
  mode: ViewMode,
  // TODO: Make use of agents and organization below if org is on premise
  agents: Agent[],
  organization: Organization,
) {
  const triggerType = getTriggerTypeFromApi(api.apiPb);
  // select all, even those without build access so the auth flow can be triggered in deployed mode
  const datasources = (yield select(selectAllPluginDatasources)) as Record<
    string,
    SupersetIntegrationDto
  >;
  const datasourceIds: string[] = [];

  const controlFlow: ReturnType<typeof selectCachedControlFlowById> =
    yield select((state) =>
      selectCachedControlFlowById(state, getV2ApiId(api)),
    );

  if (controlFlow) {
    Object.values(controlFlow.blocks).forEach((block) => {
      if (block.type === BlockType.STEP) {
        const blockConfig = (block as StepBlock).config;
        if (blockConfig.datasourceId) {
          datasourceIds.push(blockConfig.datasourceId);
        }
      }
    });
  }

  // feAuthFlows lists the auth types that may require a UI flow during
  // execution.
  const feAuthFlows: AuthType[] = [
    IntegrationAuthType.BASIC,
    IntegrationAuthType.FIREBASE,
    IntegrationAuthType.OAUTH2_PASSWORD,
    IntegrationAuthType.OAUTH2_IMPLICIT,
    IntegrationAuthType.OAUTH2_CODE,
    IntegrationAuthType.OAUTH2_TOKEN_EXCHANGE,
  ];

  const authWithFe = (authType: AuthType, authConfig: AuthConfig): boolean => {
    if (!feAuthFlows.includes(authType)) {
      return false;
    }
    if (
      authType === IntegrationAuthType.OAUTH2_CODE &&
      authConfig?.refreshTokenFromServer
    ) {
      // We login with Google on the integration page.
      // TODO: We should add a more general flag to the OAuth code to store if
      // we expect to login on the integration page or in the action itself.
      return false;
    }
    return true;
  };

  const feAuthedDatasourceIds = Object.values(datasources)
    .filter((datasource) => {
      const currentConfiguration:
        | RestApiIntegrationDatasourceConfiguration
        | undefined = getConfigFromIntegrationWithProfileId(
        datasource,
        profileId,
      );

      const authType = currentConfiguration?.authType;
      const authConfig = currentConfiguration?.authConfig;
      if (!authType || !authConfig) {
        return false;
      }

      if (
        (authType === IntegrationAuthType.OAUTH2_PASSWORD &&
          get(currentConfiguration, "authConfig.useFixedPasswordCreds")) ||
        (authType === IntegrationAuthType.BASIC &&
          get(currentConfiguration, "authConfig.shareBasicAuthCreds")) ||
        (authType === IntegrationAuthType.OAUTH2_TOKEN_EXCHANGE &&
          get(currentConfiguration, "authConfig.subjectTokenSource") ===
            "SUBJECT_TOKEN_SOURCE_STATIC_TOKEN" &&
          get(currentConfiguration, "authConfig.subjectTokenSourceStaticToken"))
      ) {
        // No need to request creds if they're already specified.
        return false;
      }

      return (
        datasourceIds.includes(datasource.id) &&
        authType &&
        authWithFe(authType, authConfig)
      );
    })
    .map((datasource) => datasource.id);
  while (feAuthedDatasourceIds.length > 0) {
    const id = feAuthedDatasourceIds[feAuthedDatasourceIds.length - 1];
    feAuthedDatasourceIds.pop();
    const apiDatasources = Object.values(datasources);
    const foundDatasource = apiDatasources.find(
      (datasource) => datasource.id === id,
    );
    if (!foundDatasource) {
      logger.warn(
        `Unexpectedly did not find datasource ${id} in set of APIs datasources: ${apiDatasources.map(
          (ds) => ds.id,
        )}`,
      );
      continue;
    }
    const currentConfiguration:
      | RestApiIntegrationDatasourceConfiguration
      | undefined = getConfigFromIntegrationWithProfileId(
      foundDatasource,
      profileId,
    );

    const authType = currentConfiguration?.authType as IntegrationAuthType;
    if (!authType) {
      logger.warn(
        `Unexpectedly attempting to authenticate datasource ${id} with no auth type`,
      );
      continue;
    }

    // Check that the current API is a UI api.
    if (triggerType !== ApiTriggerType.UI) {
      return {
        systemError: `Authentication method "${getDisplayName(
          authType,
        )}" needs access to a browser and is not supported in Workflows or Scheduled Jobs. Please choose another authentication method or use an Application instead.`,
      } as ExecutionResponse;
    }

    const enableProfiles: boolean = yield select(
      selectFlagById,
      Flag.ENABLE_PROFILES,
    );
    const isAuthenticated: { authenticated: boolean } = yield call(
      checkAuthV3,
      {
        agents,
        organization,
        orchestrator: true,
        integrationId: foundDatasource.id,
        ...(enableProfiles ? { profile } : {}),
      },
    );

    if (!isAuthenticated.authenticated) {
      if (authType === IntegrationAuthType.OAUTH2_TOKEN_EXCHANGE) {
        // For APIs that leverage token exchange, we need to check the User's IdP JWT validity
        const idpResult: { systemError?: string } = yield call(
          checkIdpAuthorization,
          id,
          authType,
        );
        if (idpResult && "systemError" in idpResult) {
          return {
            systemError: idpResult.systemError,
          } as ExecutionResponse;
        }
      } else {
        yield call(requestDatasourceAuth, id);
        yield take(ReduxActionTypes.AUTH_FINISHED);
      }
    }
  }
}

export function* getProfileAndUpdateParams(
  api: ApiDtoWithPb,
  environment: string,
  params: ExecutionParamDto[],
) {
  const organizations: Organization[] = yield select(selectOrganizations);
  const organization = Object.values(organizations)[0];
  const enableProfiles: boolean = yield select(
    selectFlagById,
    Flag.ENABLE_PROFILES,
  );

  // get profile given mode
  let profile: Profile | undefined;
  let mode: ViewMode;
  const triggerType = getTriggerTypeFromApi(api.apiPb);
  if (triggerType === ApiTriggerType.UI) {
    // select app profiles if in Application mode
    const profiles: Profiles = yield select(getAppProfilesInCurrentMode);
    profile = profiles?.selected;
    mode = yield select(getAppViewMode);
  } else {
    // this is only called in workflow/scheduled job edit mode
    profile = yield select(getProfileForTest, api.id);
    mode = ViewMode.EDITOR;
  }

  const profileId =
    organization.profiles?.find((p) => {
      return p.key === (enableProfiles ? profile?.key : environment);
    })?.id ?? "";

  // Set or override the variable `environment` in query params
  params = params.map((param) => {
    if (param.key === WorkflowExecutionParamsKey.QUERY_PARAMS) {
      return {
        key: param.key,
        value: { ...(param.value as Record<string, string>), environment },
      };
    } else {
      return param;
    }
  });

  return {
    profile,
    mode,
    profileId,
  };
}

function* checkIdpAuthorization(datasourceId: string, authType: AuthType) {
  const accessMode: AccessMode = yield select(getAccessMode);
  if (accessMode !== AccessMode.AUTH_USER) {
    return {
      systemError: `Authentication method "${getDisplayName(
        authType,
      )}" is not supported in the current access mode.`,
    } as ExecutionResponse;
  }

  const authTokenValidation: {
    hasIdpJwt: boolean;
    isIdpJwtValid?: boolean;
  } = yield call(checkIdpToken);

  if (!authTokenValidation.hasIdpJwt) {
    return {
      systemError:
        "Error getting identity provider access token. No token found. Please log in using a valid OIDC-based SSO provider. To configure or update your org's SSO, or for assistance troubleshooting, please reach out to support.",
    } as ExecutionResponse;
  }

  if (!authTokenValidation.isIdpJwtValid) {
    const currentToken: string = yield AuthProvider.generateToken();
    yield call(requestDatasourceAuth, datasourceId);
    yield take(ReduxActionTypes.AUTH_FINISHED);
    const newToken: string = yield AuthProvider.generateToken();
    const didNotReauthenticate = newToken === currentToken;
    if (didNotReauthenticate) {
      return {
        systemError:
          "Identity provider access token expired. Refresh your browser and follow prompts to reauthenticate with SSO.",
      } as ExecutionResponse;
    }
  }
}
