import { produce } from "immer";
import { createSelector } from "reselect";
import { getWidgets } from "legacy/selectors/entitiesSelector";
import { AppState } from "store/types";
import { ROOT } from "store/utils/types";
import { getAllowedChildTypesInWidgetAncestry } from "utils/getAllowedChildTypesInWidgetAncestry";
import { getApplicationSettings } from "../application/selectors";
import { WidgetCardsCategorized } from "./baseWidgets";
import slice from "./slice";

export const getAvailableWidgets = createSelector(
  slice.selector,
  (state): WidgetCardsCategorized => state.entities[ROOT].widgets,
);

export const getAllowedWidgets = createSelector(
  [
    getAvailableWidgets,
    getWidgets,
    getApplicationSettings,
    (_: AppState, widgetId: string) => widgetId,
  ],
  (
    availableWidgets,
    canvasWidgets,
    appSettings,
    widgetId: string,
  ): WidgetCardsCategorized => {
    const widgetTypes = getAllowedChildTypesInWidgetAncestry({
      widgetId,
      canvasWidgets,
      registeredComponents: appSettings?.registeredComponents,
    });

    return produce(availableWidgets, (widgets) => {
      for (const widget of widgets) {
        widget.widgetCards = widget.widgetCards.filter((card) =>
          widgetTypes.includes(card.type),
        );
      }
    });
  },
);
