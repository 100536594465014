import styled from "styled-components";
import ErrorComponent from "components/app/Error/ErrorComponent";
import { API_STATUS_CODES } from "legacy/constants/ApiConstants";

const Wrapper = styled.div`
  text-align: center;
  .bold-text {
    font-weight: ${(props) => props.theme.legacy.fontWeights[3]};
    font-size: 24px;
  }
  .page-unavailable-img {
    width: 35%;
  }
  .button-position {
    margin: auto;
  }
  height: 100%;
`;

function BranchNotFound({ title }: { title: string }) {
  return (
    <Wrapper>
      <ErrorComponent
        errorCode={API_STATUS_CODES.RESOURCE_NOT_FOUND}
        title={title}
        buttonText="Go to the default branch"
        handleButtonClick={() => {
          const url = new URL(window.location.href);
          url.searchParams.delete("branch");
          window.location.href = url.href;
        }}
      />
    </Wrapper>
  );
}

export default BranchNotFound;
