import { useAuth0 } from "@auth0/auth0-react";
import { AccessMode } from "@superblocksteam/shared";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { API_STATUS_CODES, ERROR_CODES } from "legacy/constants/ApiConstants";
import ErrorPageHeader from "legacy/pages/common/ErrorPageHeader";
import PageNotFound from "legacy/pages/common/PageNotFound";
import ServerTimeout from "legacy/pages/common/ServerTimeout";
import ServerUnavailable from "legacy/pages/common/ServerUnavailable";
import { SafeCrashReason } from "legacy/reducers/uiReducers/errorReducer";
import { getSafeCrashCurrentError } from "legacy/selectors/errorSelectors";
import { isOnEmbedRoute } from "utils/embed/messages";
import { FullPageSpinner } from "../../../components/ui/FullPageSpinner";
import { getAccessMode } from "../../selectors/usersSelectors";
import BranchNotFound from "./BranchNotFound";
import { EmbeddedErrorPage } from "./EmbeddedErrorPage";
import PageNoAgents from "./PageNoAgents";
import PageRefreshRequired from "./PageRefreshRequired";
import PageVerificationFailed from "./PageVerificationFailed";
import RequestUnathorized from "./RequestUnauthorized";
import ResourceForbidden from "./ResourceForbidden";
import ResourceNotFoundInBranch from "./ResourceNotFoundInBranch";

export interface ErrorPageProps {
  code: number | string;
  reason?: string;
}

const ErrorPage = (props: ErrorPageProps) => {
  const { code, reason } = props;
  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    error: authError,
  } = useAuth0();
  const accessMode = useSelector(getAccessMode);
  const currentError = useSelector(getSafeCrashCurrentError);

  const shouldRedirectToLogin =
    !isAuthenticated && accessMode === AccessMode.VISITOR && !isOnEmbedRoute();
  useEffect(() => {
    if (!isLoading && shouldRedirectToLogin) {
      const connectionName =
        new URLSearchParams(window.location.search).get("connection") ??
        undefined;

      loginWithRedirect({
        // Set the return URL to the path and query params For example, if the
        // original URL path is `/applications/:appId/edit` and there is one query
        // param `environment=production`, and one hash `code=foo`, the return URL will be
        // `/applications/:appId/edit?environment=production#code=foo`.
        // The hash codes are used during in-app OAuth callbacks to authorize datasources.
        appState: {
          returnUrl: `${window.location.pathname}${window.location.search}${window.location.hash}`,
        },
        connection: connectionName,
      });
    }
  }, [isLoading, shouldRedirectToLogin, loginWithRedirect, authError]);

  if (isLoading || shouldRedirectToLogin) {
    return <FullPageSpinner />;
  }
  if (isOnEmbedRoute()) {
    return <EmbeddedErrorPage code={code} />;
  }
  let pageContent;
  switch (code) {
    case API_STATUS_CODES.RESOURCE_NOT_FOUND:
      // TODO: find a better way to detect the branch not found case
      if (
        currentError?.message?.includes("branch does not contain files for")
      ) {
        pageContent = <BranchNotFound title={currentError?.message} />;
      } else if (
        currentError?.message?.includes(
          "does exist but it has no branch named",
        ) ||
        reason === SafeCrashReason.BRANCH_NOT_FOUND
      ) {
        pageContent = (
          <BranchNotFound title="The requested branch does not exist" />
        );
      } else {
        // generic 404 page
        pageContent = <PageNotFound />;
      }
      break;
    case API_STATUS_CODES.TIMEOUT:
      pageContent = <ServerTimeout />;
      break;
    case API_STATUS_CODES.RESOURCE_FORBIDDEN:
      pageContent = <ResourceForbidden />;
      break;
    case API_STATUS_CODES.REQUEST_UNAUTHORIZED:
      pageContent = <RequestUnathorized />;
      break;
    case ERROR_CODES.REFRESH_REQUIRED: {
      pageContent = <PageRefreshRequired />;
      break;
    }
    case ERROR_CODES.VERIFY_RESOURCES_FAILED: {
      pageContent = <PageVerificationFailed />;
      break;
    }
    case ERROR_CODES.NO_AGENTS: {
      pageContent = <PageNoAgents />;
      break;
    }
    case API_STATUS_CODES.SERVER_ERROR:
    default:
      if (
        code === 400 &&
        currentError?.message?.includes("not found in remote branch")
      ) {
        pageContent = <ResourceNotFoundInBranch />;
      } else {
        pageContent = <ServerUnavailable />;
      }
      break;
  }
  return (
    <>
      <ErrorPageHeader />
      {pageContent}
    </>
  );
};

export default ErrorPage;
