import { PermissionedEntities, RbacRole } from "@superblocksteam/shared";
import {
  getApplicationIdFromUrl,
  getScheduledJobIdFromUrl,
  getWorkflowIdFromUrl,
  isApplicationEditUrl,
} from "@superblocksteam/shared";
import { Button } from "antd";
import Text from "antd/lib/typography/Text";

import Title from "antd/lib/typography/Title";
import { useCallback, useState } from "react";
import styled from "styled-components";
import { ReactComponent as AppIllustrationColored } from "assets/images/app-illustration-round-colored.svg";
import { ReactComponent as AppIllustration } from "assets/images/app-illustration-round.svg";
import { ReactComponent as LockImage } from "assets/images/lock-round.svg";
import { ReactComponent as ScheduledJobIllustrationColored } from "assets/images/scheduled-job-illustration-round-colored.svg";
import { ReactComponent as ScheduledJobIllustration } from "assets/images/scheduled-job-illustration-round.svg";
import { ReactComponent as SendApplication } from "assets/images/send-application.svg";
import { ReactComponent as SendScheduledJob } from "assets/images/send-scheduled-job.svg";
import { ReactComponent as SendWorkflow } from "assets/images/send-workflow.svg";
import { ReactComponent as WorkflowIllustrationColored } from "assets/images/workflow-illustration-round-colored.svg";
import { ReactComponent as WorkflowIllustration } from "assets/images/workflow-illustration-round.svg";
import { CenteredWrapper } from "components/ui/Wrapper";
import { API_STATUS_CODES } from "legacy/constants/ApiConstants";
import { HOME_URL } from "legacy/constants/routes";
import { requestAccessForResource } from "pages/Permissions/client";
import { isOnEmbedRoute } from "utils/embed/messages";
import { EntityType, getEntityLabel } from "utils/entity";
import { sendErrorUINotification } from "utils/notification";
import { EmbeddedErrorPage } from "./EmbeddedErrorPage";

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const EntityImageWrapper = styled.div`
  display: flex;
`;

const StatusImageWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: -56px;
`;

const ButtonRow = styled.div`
  display: flex;
  margin-top: 30px;
`;

interface Props {
  entityType: EntityType;
}

const RequestAccess = (props: Props) => {
  const [requestSent, setRequestSent] = useState(false);
  const url = window.location.pathname;
  let entityImage = null;
  let statusImage = null;
  let resourceId: string | undefined;
  let resourceType: PermissionedEntities | undefined;
  switch (props.entityType) {
    case EntityType.APPLICATION:
      resourceId = getApplicationIdFromUrl(url);
      resourceType = PermissionedEntities.APPLICATION;
      entityImage = requestSent ? (
        <AppIllustrationColored />
      ) : (
        <AppIllustration />
      );
      statusImage = requestSent ? <SendApplication /> : <LockImage />;
      break;
    case EntityType.WORKFLOW:
      resourceId = getWorkflowIdFromUrl(url);
      resourceType = PermissionedEntities.API;
      entityImage = requestSent ? (
        <WorkflowIllustrationColored />
      ) : (
        <WorkflowIllustration />
      );
      statusImage = requestSent ? <SendWorkflow /> : <LockImage />;
      break;
    case EntityType.SCHEDULED_JOB:
      resourceId = getScheduledJobIdFromUrl(url);
      resourceType = PermissionedEntities.API;
      entityImage = requestSent ? (
        <ScheduledJobIllustrationColored />
      ) : (
        <ScheduledJobIllustration />
      );
      statusImage = requestSent ? <SendScheduledJob /> : <LockImage />;
    // TODO: Add datasource
  }
  const requestAccess = useCallback(async () => {
    let role = RbacRole.BUILDER;
    if (
      props.entityType === EntityType.APPLICATION &&
      !isApplicationEditUrl(window.location.pathname)
    ) {
      role = RbacRole.VIEWER;
    }

    if (resourceId && resourceType) {
      try {
        setRequestSent(false);
        await requestAccessForResource(resourceId, resourceType, role);
      } catch (err: any) {
        sendErrorUINotification({
          message: `failed to request for current resource: ${err?.message}`,
        });
      }
      setRequestSent(true);
    }
  }, [props.entityType, resourceId, resourceType]);

  if (isOnEmbedRoute()) {
    return <EmbeddedErrorPage code={API_STATUS_CODES.RESOURCE_FORBIDDEN} />;
  }
  return (
    <CenteredWrapper>
      <ImageWrapper>
        <EntityImageWrapper>{entityImage}</EntityImageWrapper>
        <StatusImageWrapper>{statusImage}</StatusImageWrapper>
      </ImageWrapper>
      <Title level={3}>
        {requestSent
          ? "Request Sent"
          : `You need access to this ${getEntityLabel(props.entityType)}`}
      </Title>
      <Text>
        {requestSent
          ? "You'll get an email letting you know if your request was approved"
          : `Send a request to the builders of this  ${getEntityLabel(
              props.entityType,
            )} for access`}
      </Text>
      <ButtonRow>
        {requestSent ? (
          <Button
            type={"primary"}
            onClick={() => (window.location.pathname = HOME_URL)}
          >
            {"Return Home"}
          </Button>
        ) : (
          <>
            <Button
              type={"primary"}
              onClick={requestAccess}
              style={{ marginRight: 10 }}
            >
              {"Request Access"}
            </Button>
            <Button onClick={() => (window.location.pathname = HOME_URL)}>
              {"Return Home"}
            </Button>
          </>
        )}
      </ButtonRow>
    </CenteredWrapper>
  );
};

export default RequestAccess;
